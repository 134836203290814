import React from "react";
import "./App.scss";
import { SocialIcon } from 'react-social-icons'

/**
 * Uses Tailwind CSS for styling
 * Tailwind file is imported in App.css
 */

export default function App() {
  // let faqEntries = [
  //   {title: "What kind of game is Nightmare Party?", description: "Nightmare Party is a 5 player co-op multiplayer horror FPS developed in Unreal Engine 5. You are trapped and your goal is to solve riddles, complete tasks and find the exit key while a demon and its minion(s) try to kill you."}
  // ];

  return (
    <div className="app">
      <nav className="header">
        <div className="center-container">
          <a className="header-logo-link" href="/">
           <img className="header-logo" src="logos/nightmare-party-logo-drawn-dark-bg-sm.png" alt="Nightmare Party logo" />
            <span className="header-presskit">Presskit</span>
          </a>
          <div className="header-nav">
            <a href="#videos">Videos</a>
            <a href="#description">Description</a>
            <a href="#factsheet">Factsheet</a>
            <a href="#images">Images</a>
            <a href="#logo-icon">Logo & Icon</a>
            <a href="#history">History</a>
            <a href="#permissions">Permissions</a>
            <a href="#contact">Contact</a>
          </div>
          <div className="social-icons-container small float-right">
            <SocialIcon url="https://discord.gg/HnsHDzFpUs" fgColor="#fff" />
            <SocialIcon url="https://www.reddit.com/r/nightmarepartygame/" fgColor="#fff" />
            <SocialIcon url="https://www.facebook.com/nightmarepartygame" fgColor="#fff" />
            <SocialIcon url="https://twitter.com/MadRavenGames" fgColor="#fff" />
          </div>
        </div>
      </nav>

      <div className="section-bg hero">
        <div className="center-container no-max-width">
          <img src="logos/nightmare-party-logo-drawn-dark-bg.png" alt="Nightmare Party logo" className="np-logo-hero" />
          <h1 className="text-center mb-none hero-h1">
            <span className="small">WELCOME TO YOUR</span>
            <span className="big">NIGHTMARE</span>
          </h1>
          <h2 className="text-center mt-none">
            <span className="dark-bg">
              COMING 2024
            </span>
          </h2>

          <iframe title="Steam" src="https://store.steampowered.com/widget/1644910/" frameBorder="0" className="steam-cta" />
          <a className="social-banner-link" href="https://discord.gg/HnsHDzFpUs" target="_blank" rel="noreferrer">
            <img src="social-link-banners-discord.png" alt="Discord community link"/>
          </a>
          <a className="social-banner-link" href="https://www.reddit.com/r/nightmarepartygame/" target="_blank" rel="noreferrer">
            <img src="social-link-banners-reddit.png" alt="Reddit community link"/>
          </a>
          <div className="social-icons-container text-center">
            <SocialIcon url="https://twitter.com/MadRavenGames" bgColor="transparent" fgColor="#fff" />
            <SocialIcon url="https://www.facebook.com/nightmarepartygame" bgColor="transparent" fgColor="#fff" />
          </div>
        </div>
      </div>

      <div id="videos" className="section-bg light faq">
        <div className="center-container">
          <h2 className="text-center">
            Videos
          </h2>

          <div className="faq-entry">
            <h3>Steam trailer</h3>
            <p>
              <a href="https://www.youtube.com/watch?v=yG42hk2Szps" target="_blank" rel="noreferrer">Youtube link</a><br/>
              <a href="https://drive.google.com/file/d/1QjFtYUvUUOTy2-WJ46e8RcdhFHpFeMVt/view?usp=sharing" target="_blank" rel="noreferrer">Download from Google Drive</a>
            </p>
            <div className="video-container full-width">
              <div className='embed-container'>
                <iframe title="Video" src='https://www.youtube.com/embed/yG42hk2Szps' frameBorder="0" scrolling="0" allowFullScreen="1" />
              </div>
            </div>
          </div>

          <div className="faq-entry">
            <h3>Devlog #1 | May 2022</h3>
            <p>
              <a href="https://www.youtube.com/watch?v=9RUx0_RTjvo" target="_blank" rel="noreferrer">Youtube link</a><br/>
              <a href="https://drive.google.com/file/d/1iXVN8Dd2cYrsfp2B59RZ0dMBGdRhyC8G/view?usp=sharing" target="_blank" rel="noreferrer">Download from Google Drive</a>
            </p>
            <div className="video-container full-width">
              <div className='embed-container'>
                <iframe title="Video" src='https://www.youtube.com/embed/9RUx0_RTjvo' frameBorder="0" scrolling="0" allowFullScreen="1" />
              </div>
            </div>
          </div>

        </div>
      </div>

      <div id="description" className="section-bg light faq">
        <div className="center-container">
          <h2 className="text-center">
            Description
          </h2>

          <div className="faq-entry">
            <p>
              Nightmare Party is a 1-5 player co-op multiplayer horror FPS. You are trapped and your goal is to survive and escape while a demon and its minions try to kill you.
              <br/>
              <ul>
                <li>solve riddles and complete various tasks with your friends to obtain keys and other objective items</li>
                <li>in some scenarios teamplay is crucial</li>
                <li>loot ammo, sedatives and painkillers to survive</li>
                <li>kill enemies or run and hide</li>
                <li>keep your madness level under 100% or you'll lose HP and die</li>
                <li>your final goal is to escape the level</li>
              </ul>
            </p>
            <p>The game can be played alone and there are public and private lobbies.</p>
          </div>

        </div>
      </div>

      <div id="factsheet" className="section-bg light faq">
        <div className="center-container">
          <h2 className="text-center">
            Factsheet
          </h2>

          <div className="faq-entry">
            <table className="full-width">
              <tbody>
                <tr>
                  <th>Genre:</th>
                  <td>
                    5 player multiplayer co-op, horror, FPS, action, survival
                  </td>
                </tr>
                <tr>
                  <th>Players:</th>
                  <td>
                    1-5 players<br/>
                    Public and private lobbies
                  </td>
                </tr>
                <tr>
                  <th>Developer:</th>
                  <td>
                    Mad Raven Games<br/>
                    Based in Budapest, Hungary<br/>
                    Currently Balázs Varga only
                  </td>
                </tr>
                <tr>
                  <th>Current stage:</th>
                  <td>
                    pre-alpha
                  </td>
                </tr>
                <tr>
                  <th>Release date:</th>
                  <td>
                    Q4 2024
                  </td>
                </tr>
                <tr>
                  <th>Platforms:</th>
                  <td>
                    Steam PC, Windows (2024)<br/>
                    PS5 planned (release date TBD)<br/>
                    Xbox Series X|S planned (release date TBD)
                  </td>
                </tr>
                <tr>
                  <th>Engine:</th>
                  <td>
                    Unreal Engine 5
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>

      <div id="images" className="section-bg light faq">
        <div className="center-container">
          <h2 className="text-center">
            Images
          </h2>

          <div className="faq-entry">
            <h3>Screenshots</h3>
            <a href="/screenshots/nightmare-party-screenshots.zip" target="_blank" rel="noreferrer">Download all screenshots as .zip</a><br/>
            Or click on the images to download them:<br/>
            <a href="/screenshots/1.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/screenshots/1-sm.png" title="Screenshot 1"/>
            </a>
            <a href="/screenshots/2.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/screenshots/2-sm.png" title="Screenshot 2"/>
            </a>
            <a href="/screenshots/3.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/screenshots/3-sm.png" title="Screenshot 3"/>
            </a>
            <a href="/screenshots/4.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/screenshots/4-sm.png" title="Screenshot 4"/>
            </a>
            <a href="/screenshots/5.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/screenshots/5-sm.png" title="Screenshot 5"/>
            </a>
            <a href="/screenshots/6.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/screenshots/6-sm.png" title="Screenshot 6"/>
            </a>

            <a href="/screenshots/7.jpg" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/screenshots/7-sm.jpg" title="Screenshot 6"/>
            </a>
            <a href="/screenshots/8.jpg" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/screenshots/8-sm.jpg" title="Screenshot 6"/>
            </a>
            <a href="/screenshots/9.jpg" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/screenshots/9-sm.jpg" title="Screenshot 6"/>
            </a>
            <a href="/screenshots/10.jpg" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/screenshots/10-sm.jpg" title="Screenshot 6"/>
            </a>
            <a href="/screenshots/11.jpg" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/screenshots/11-sm.jpg" title="Screenshot 6"/>
            </a>
            <a href="/screenshots/12.jpg" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/screenshots/12-sm.jpg" title="Screenshot 6"/>
            </a>
          </div>

        </div>
      </div>

      <div id="logo-icon" className="section-bg light faq">
        <div className="center-container">
          <h2 className="text-center">
            Logo & Icon
          </h2>

          <div className="faq-entry">
            <h3>Nightmare Party</h3>
            <h4>Logo</h4>
            <a href="/logos/nightmare-party-logos.zip">Download logo files as .zip</a><br/>
            Or click on the images to download them:<br/>
            <a href="/logos/nightmare-party-logo-drawn-dark-bg.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/logos/nightmare-party-logo-drawn-dark-bg-sm.png" title="Nightmare Party logo dark background"/>
            </a>
            <a href="/logos/nightmare-party-logo-drawn-light-bg.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/logos/nightmare-party-logo-drawn-light-bg-sm.png" title="Nightmare Party logo light background"/>
            </a>
            <h4>Icon</h4>
            <a href="/icons/nightmare-party-icons.zip">Download icon files as .zip</a><br/>
            Or click on the images to download them:<br/>
            <a href="/icons/icon-without-bg.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/icons/icon-without-bg-sm.png" title="Nightmare Party icon without background"/>
            </a>
            <a href="/icons/icon-with-black-bg.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/icons/icon-with-black-bg-sm.png" title="Nightmare Party icon with black background"/>
            </a>
            <a href="/icons/icon-with-white-bg.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/icons/icon-with-white-bg-sm.png" title="Nightmare Party icon with white background"/>
            </a>
          </div>

          <div className="faq-entry">
            <h3>Mad Raven Games</h3>
            <a href="/logos/mad-raven-games-logos.zip" target="_blank" rel="noreferrer">Download logo files as .zip</a><br/>
            Or click on the images to download them:<br/>
            <br/>
            <a href="/logos/mad-raven-games-wide-logo-white.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/logos/mad-raven-games-wide-logo-white-sm.png" title="Mad Raven Games logo white"/>
            </a>
            <a href="/logos/mad-raven-games-wide-logo-black.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/logos/mad-raven-games-wide-logo-black-sm.png" title="Mad Raven Games logo black"/>
            </a>
            <a href="/logos/mad-raven-games-wide-logo-gray.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/logos/mad-raven-games-wide-logo-gray-sm.png" title="Mad Raven Games logo gray"/>
            </a>
            <a href="/logos/mad-raven-games-wide-logo-purple.png" className="dl-image" target="_blank" rel="noreferrer">
              <img src="/logos/mad-raven-games-wide-logo-purple-sm.png" title="Mad Raven Games logo purple"/>
            </a>
          </div>

        </div>
      </div>

      <div id="history" className="section-bg light faq">
        <div className="center-container">
          <h2 className="text-center">
            History
          </h2>

          <div className="faq-entry">
            <h3></h3>
            <p>
              In October 2020, Balázs Varga was actively playing multiplayer FPS games with his friends. They really liked playing Rainbow Six Siege and Call of Duty, but they also enjoyed horror co-op games.
              One day, after trying out a multiplayer horror co-op game, he thought "I could make my own, it would be fun". He wanted to create something scary with a great gunplay system to play with his friends.
              His journey in game development began on that day. He started learning Unity
              and Blender, he tried to understand how 3D works in general. After a few months, he switched from Unity to Unreal Engine and from Blender to Maya. He completed a Maya course, so
              he could create his own models and animations. A few months later he realized that he is only one person, he can't make everything alone. He started buying models
              from the marketplace, but he is still creating the FPS animations and materials, he does the sound design and all coding in C++ himself. In the past few months he and his
              wife are actively testing and playing the game (and are really enjoying it).
            </p>
            <p>
              Mad Raven Games is currently a one man studio, but Balázs hopes he will find passionate people in the future.
            </p>
          </div>

        </div>
      </div>

      <div id="permissions" className="section-bg light faq">
        <div className="center-container">
          <h2 className="text-center">
            Permissions
          </h2>

          <div className="faq-entry">
            <h3>Monetization Permission</h3>
            <p>
              Mad Raven Games allows for the contents of Nightmare Party to be published through video broadcasting services for any commercial or non-commercial purposes.
              Monetization of videos and images created containing assets from Nightmare Party is legally & explicitly allowed by Mad Raven Games.
            </p>
          </div>

        </div>
      </div>

      <div id="contact" className="section-bg light faq">
        <div className="center-container">
          <h2 className="text-center">
            Contact
          </h2>

          <div className="faq-entry">
            <h3>Email</h3>
            <p>
              Press requests, business inquiries, etc.:<br/>
              <a href="mailto:contact@madravengames.com">contact@madravengames.com</a>
            </p>
          </div>

          <div className="faq-entry">
            <h3>Discord</h3>
            <p>
              Development updates, discussion:<br/>
              <a href="https://discord.gg/HnsHDzFpUs" target="_blank" rel="noreferrer">Discord Server</a>
            </p>
          </div>

          <div className="faq-entry">
            <h3>Reddit</h3>
            <p>
              <a href="https://www.reddit.com/r/nightmarepartygame/" target="_blank" rel="noreferrer">Reddit Community</a>
            </p>
          </div>

          <div className="faq-entry">
            <h3>Facebook</h3>
            <p>
              <a href="https://www.facebook.com/nightmarepartygame" target="_blank" rel="noreferrer">Facebook</a>
            </p>
          </div>

          <div className="faq-entry">
            <h3>Twitter</h3>
            <p>
              <a href="https://twitter.com/MadRavenGames" target="_blank" rel="noreferrer">Twitter</a>
            </p>
          </div>

        </div>
      </div>

      <div className="section-bg">
        <div className="center-container text-center">
          <img className="mad-raven-games-logo" src="mad-raven-games-wide-logo-grey.png" alt="Mad Raven Games logo"/>
          <p>
            Copyright Mad Raven Games.<br />
            All rights reserved. <br />
            <a href="mailto:contact@madravengames.com">contact@madravengames.com</a>
          </p>
        </div>
      </div>
    </div>
  );
}

// {faqEntries.map(({ title, description }, i) => {
//   return (<FaqSection title={title} description={description} />)
// })}
// function FaqSection({ title, description }) {
//   return (
//     <div className="faq-entry">
//       <h3>{title}</h3>
//       <p>
//         {description}
//       </p>
//     </div>
//   );
// }
